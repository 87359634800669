<template>
    <div>
        <div v-for="(name,id) in row.item.customers"
             :key="id"
        >
            <a href="#"
               @click.prevent="$root.$children[0].openModal('customer-modal', {id:id}, refreshTable)">
                {{ name }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>